import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';
import InspirationsSection from '@components/inspirations-section/inspirations-section';
import OfferSection from '@components/offer-section/offer-section';
import Hero from '@components/hero/hero';

import * as s from './index.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';

const Home = () => {

  const data = useStaticQuery(graphql`
    query {
      metaImg: file(relativePath: { eq: "images/metaImg.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <MainLayout>


      <Seo
        title='Meble mofu - Zainspiruj się prostotą!'
        description='MOFU to rodzinna firma meblarska, bazująca na autorskiej i innowacyjnej metodzie łączenia ze sobą mebli. Nasz system opieramy na trwałości, prostocie złożenia i uniwersalności.'
      />

      <Hero />

      <InspirationsSection />

      <hr className={`${s.separator} ${s_wrapper.apply}`} />

      <OfferSection />

    </MainLayout>
  );

}

export default Home;
