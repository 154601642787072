import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Slick from 'react-slick';

import * as s from './inspirations-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import './slider.scss';

import Inspiration12Mp4 from '@assets/video/Inspiration12.mp4';

const InspirationsSection = () => {

    const data = useStaticQuery(graphql`
        query {
            inspiration_1: file(relativePath: { eq: "images/inspirations/01.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_2: file(relativePath: { eq: "images/inspirations/02.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_3: file(relativePath: { eq: "images/inspirations/03.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_4: file(relativePath: { eq: "images/inspirations/04.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_5: file(relativePath: { eq: "images/inspirations/05.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_6: file(relativePath: { eq: "images/inspirations/06.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_7: file(relativePath: { eq: "images/inspirations/07.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_8: file(relativePath: { eq: "images/inspirations/08.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_9: file(relativePath: { eq: "images/inspirations/09.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_10: file(relativePath: { eq: "images/inspirations/10.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            inspiration_11: file(relativePath: { eq: "images/inspirations/11.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
        }
    `);


    const mockupData = [
        {
            img: getImage(data.inspiration_1),
            text: '',
        },
        {
            img: getImage(data.inspiration_2),
            text: '',
        },
        {
            img: getImage(data.inspiration_3),
            text: '',
        },
        {
            img: getImage(data.inspiration_4),
            text: '',
        },
        {
            img: getImage(data.inspiration_5),
            text: '',
        },
        {
            img: getImage(data.inspiration_6),
            text: '',
        },
        {
            img: getImage(data.inspiration_7),
            text: '',
        },
        {
            img: getImage(data.inspiration_8),
            text: '',
        },
        {
            img: getImage(data.inspiration_9),
            text: '',
        },
        {
            img: getImage(data.inspiration_10),
            text: '',
        },
        {
            img: getImage(data.inspiration_11),
            text: '',
        },
    ]

    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a className="dot">{i}</a>;
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    return (
        <section className={`${s.inspirations_section} inspirations_section ${s_wrapper.apply} ${s_wrapper.apply_height}`} id='inspiracje'>

            <h2>Zainspiruj się prostotą!</h2>
            <p className={`${s.subtitle}`}>
                Zobacz nasze pomysły jak można wykorzystać meble MOFU
            </p>

            <Slick {...settings} className={s.slider_wrapper}>

                {/* <div className={s.slide_outer}>
                    <div className={s.slide}>
                        <video controls>
                            <source src={HeroWebM} type="video/webm" />
                            <source src={Inspiration12Mp4} type="video/mp4" />
                        </video>
                        <p className={s.text}>
                        </p>
                    </div>
                </div> */}

                {mockupData.map((el, id) => (
                    <div key={id} className={s.slide_outer}>
                        <div className={s.slide}>
                            <GatsbyImage image={el.img} className={s.img} alt='Zdjęcie inspiracji' />
                            <p className={s.text}>
                                {el.text}
                            </p>
                        </div>
                    </div>
                ))}

            </Slick>

        </section>
    )
};

export default InspirationsSection;
