import React, { useReducer } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from 'gatsby-plugin-image';

import OfferItem from '@components/offer-item/offer-item';
import OrderPopup from '@components/order-popup/order-popup';

import * as s from './offer-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';


const OfferSection = () => {

    const data = useStaticQuery(graphql`
        query {
            offer_a_1: file(relativePath: { eq: "images/offer/1/1400-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_2: file(relativePath: { eq: "images/offer/1/1400-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_3: file(relativePath: { eq: "images/offer/1/1400-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_4: file(relativePath: { eq: "images/offer/1/1400-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_5: file(relativePath: { eq: "images/offer/1/1400-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_6: file(relativePath: { eq: "images/offer/1/1400-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
            
            
            
            offer_a_1_full: file(relativePath: { eq: "images/offer/1/2100-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_2_full: file(relativePath: { eq: "images/offer/1/2100-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_3_full: file(relativePath: { eq: "images/offer/1/2100-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_4_full: file(relativePath: { eq: "images/offer/1/2100-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_5_full: file(relativePath: { eq: "images/offer/1/2100-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_a_6_full: file(relativePath: { eq: "images/offer/1/2100-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
            
            
            
            offer_b_1: file(relativePath: { eq: "images/offer/2/1400-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_2: file(relativePath: { eq: "images/offer/2/1400-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_3: file(relativePath: { eq: "images/offer/2/1400-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_4: file(relativePath: { eq: "images/offer/2/1400-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_5: file(relativePath: { eq: "images/offer/2/1400-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_6: file(relativePath: { eq: "images/offer/2/1400-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
            
            
            
            offer_b_1_full: file(relativePath: { eq: "images/offer/2/2100-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_2_full: file(relativePath: { eq: "images/offer/2/2100-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_3_full: file(relativePath: { eq: "images/offer/2/2100-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_4_full: file(relativePath: { eq: "images/offer/2/2100-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_5_full: file(relativePath: { eq: "images/offer/2/2100-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_b_6_full: file(relativePath: { eq: "images/offer/2/2100-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
            
            
            offer_c_1: file(relativePath: { eq: "images/offer/3/1400-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_2: file(relativePath: { eq: "images/offer/3/1400-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_3: file(relativePath: { eq: "images/offer/3/1400-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_4: file(relativePath: { eq: "images/offer/3/1400-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_5: file(relativePath: { eq: "images/offer/3/1400-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_6: file(relativePath: { eq: "images/offer/3/1400-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
            
            
            
            offer_c_1_full: file(relativePath: { eq: "images/offer/3/2100-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_2_full: file(relativePath: { eq: "images/offer/3/2100-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_3_full: file(relativePath: { eq: "images/offer/3/2100-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_4_full: file(relativePath: { eq: "images/offer/3/2100-4.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_5_full: file(relativePath: { eq: "images/offer/3/2100-5.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            offer_c_6_full: file(relativePath: { eq: "images/offer/3/2100-6.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
            
        }
    `);


    const mockupData = [
        {
            img: [
                getImage(data.offer_a_1),
                getImage(data.offer_a_2),
                getImage(data.offer_a_3),
                getImage(data.offer_a_4),
                getImage(data.offer_a_5),
                getImage(data.offer_a_6),
            ],
            img_full: [
                getImage(data.offer_a_1_full),
                getImage(data.offer_a_2_full),
                getImage(data.offer_a_3_full),
                getImage(data.offer_a_4_full),
                getImage(data.offer_a_5_full),
                getImage(data.offer_a_6_full),
            ],
            title: 'ZESTAW BASIC',
            old_price: 1998,
            price: 1347,
            premiere_date: '22.02.2022',
            shipping_date: '27.02.2022',
            about: 'Moduł BASIC charakteryzuje się prostokątną podstawą o wymiarach 100x35x40cm (z standardowymi zakończeniami 2,5 cm). Moduły są stworzone do tego aby je łączyć z  możliwością dowolnej kombinacji. Może z nich powstać zarówno klasyczna meblościanka jak i elegancki regał. Mogą przybierać dowolne kształty, zmieniać fronty jak i nawet materiał z którego zostaną wykonane, wszystko wg indywidulanych potrzeb. Sprawdzą się więc idealnie w  każdego rodzaju pomieszczeniu. Najlepsze jest to, że to Ty sam decydujesz jaki finalnie kształt mają przyjąć, gwarantując Ci przy tym oryginalny wystrój Twojego wnętrza!',
            contents: [
            ],
            sizes: [
                'Szerokość : 100 cm',
                'Wysokość : 106 cm',
                'Głębokość : 35 cm',
                'Grubość półki : 19 mm',
                'Nośność półki : max 30 kg',
            ],
            color: 'normal',
            reversed: true
        },
        {
            img: [
                getImage(data.offer_b_1),
                getImage(data.offer_b_2),
                getImage(data.offer_b_3),
                getImage(data.offer_b_4),
                getImage(data.offer_b_5),
                getImage(data.offer_b_6),
            ],
            img_full: [
                getImage(data.offer_b_1_full),
                getImage(data.offer_b_2_full),
                getImage(data.offer_b_3_full),
                getImage(data.offer_b_4_full),
                getImage(data.offer_b_5_full),
                getImage(data.offer_b_6_full),
            ],
            title: 'ZESTAW CUBE',
            old_price: 1998,
            price: 1347,
            premiere_date: '22.02.2022',
            shipping_date: '27.02.2022',
            about: 'Moduł CUBE podstawą modułu jest kwadtrat o wymiarach 55x35x40cm (z standardowymi zakończeniami 2,5 cm) i to na nim opiera się budowa całego systemu. W porównaniu z modułem BASIC jest mniejszy i nieco niższy, przez co nadaje się idalnie do mniejszych powierzchni. Doadatkowo ze względu na swój kształt jest też bardziej ustawny. Posiada również wszystkie zalety swojego większego odpowiednika!',
            contents: [
            ],
            sizes: [
                'Szerokość : 100 cm',
                'Wysokość : 106 cm',
                'Głębokość : 35 cm',
                'Grubość półki : 19 mm',
                'Nośność półki : max 30 kg',
            ],
            color: 'more_pink',
        },
        {
            img: [
                getImage(data.offer_c_1),
                getImage(data.offer_c_2),
                getImage(data.offer_c_3),
                getImage(data.offer_c_4),
                getImage(data.offer_c_5),
                getImage(data.offer_c_6),
            ],
            img_full: [
                getImage(data.offer_c_1_full),
                getImage(data.offer_c_2_full),
                getImage(data.offer_c_3_full),
                getImage(data.offer_c_4_full),
                getImage(data.offer_c_5_full),
                getImage(data.offer_c_6_full),
            ],
            title: 'ZESTAW BUBU',
            old_price: 1998,
            price: 1347,
            premiere_date: '22.02.2022',
            shipping_date: '27.02.2022',
            about: 'Moduł BUBU o wmirach 100x38x40cm (z standardowymi zakończeniami 2,5 cm) powstał z myślą o dzięcięcych potrzebach w każdym wieku! Dzięki uniwersalnym kształtom oraz możliwości wymiany frontów, meble rosną razem z naszymi pociechami. Można je dowolnie przestawiać, ustawiać oraz zmnieniać ich styl, który będzie idealny dla wymagąjących nastolatków jaki i pełnych pasji młodych odkrywców dzicięcych zabaw.',
            contents: [
            ],
            sizes: [
                'Szerokość : 100 cm',
                'Wysokość : 106 cm',
                'Głębokość : 35 cm',
                'Grubość półki : 19 mm',
                'Nośność półki : max 30 kg',
            ],
            color: 'pink',
            reversed: true
        }
    ]

    const [orderPopup, dispatchOrderPopup] = useReducer((state, action) => {return action;}, '');

    const renderPopup = () => {
        if(orderPopup !== ''){
            return <OrderPopup selectedProduct={orderPopup} managePopup={dispatchOrderPopup} />
        }
    }

    return (
        <section className={`${s.offer_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

            {mockupData.map((el, id) => (
                <OfferItem
                    key={id}
                    data={el}
                    managePopup={(popup) => dispatchOrderPopup(popup)}
                />
            ))}

            {renderPopup()}

        </section>
    )
};

export default OfferSection;
