// extracted by mini-css-extract-plugin
export var close = "offer-item-module--close--nbmxr";
export var content = "offer-item-module--content--FtERR";
export var contents = "offer-item-module--contents--LdTR3";
export var curr = "offer-item-module--curr--Su16E";
export var dates = "offer-item-module--dates--UPJmZ";
export var form = "offer-item-module--form--D6ezr";
export var images = "offer-item-module--images--VBBBG";
export var img = "offer-item-module--img--EI6Xo";
export var info = "offer-item-module--info--odKv-";
export var more_pink = "offer-item-module--more_pink--GE2x3";
export var offer_item = "offer-item-module--offer_item--AAKTi";
export var old = "offer-item-module--old--t6fQh";
export var pink = "offer-item-module--pink--0TbFY";
export var premiere = "offer-item-module--premiere---7Sl2";
export var price = "offer-item-module--price--DdZLJ";
export var reversed = "offer-item-module--reversed--XUj5S";
export var shadow = "offer-item-module--shadow--6NEAv";
export var shipping = "offer-item-module--shipping---iRbb";
export var sizes = "offer-item-module--sizes--9SaHA";
export var slide = "offer-item-module--slide--fXqbY";
export var slide_outer = "offer-item-module--slide_outer--cbtCl";
export var slider_wrapper = "offer-item-module--slider_wrapper--7Htjj";
export var wrapper = "offer-item-module--wrapper--r+ECt";