import React from 'react';


import * as s from './hero.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';

import HeroMp4 from '@assets/video/hero.mp4';
import HeroWebM from '@assets/video/hero.webm';

const Hero = () => {



    return (
        <section className={`${s.hero} ${s_wrapper.apply} ${s_wrapper.apply_height}`} id='o-mofu'>

            <div className={s.content}>
                <h1>Czym jest MOFU?</h1>

                <p>
                    MOFU to rodzinna firma meblarska, bazująca na autorskiej i innowacyjnej metodzie
                    łączenia ze sobą mebli. Nasz system opieramy na trwałości, prostocie złożenia i uniwersalności.
                    Dzięki użyciu litego drewna nasze meble pozwalają użytkownikom zarówno na dopasowanie każdego
                    produktu do własnych i zmiennych potrzeb, jak i na cieszenie się zakupem przez długie lata.
                    Możliwość modyfikacji i rozbudowy zestawów w niemal dowolny sposób i w dowolnym momencie
                    użytkowania sprawia, że meble firmy MOFU zawsze będą spełniały oczekiwania naszych klientów.
                    Zapewniamy, że nasze produkty, dzięki swojemu niezawodnemu i elastycznemu systemowi, są
                    ponadczasowe i satysfakcjonujące dla odbiorców w każdym wieku
                </p>

            </div>

            <div className={s.film}>

                <video controls>
                    <source src={HeroWebM} type="video/webm" />
                    <source src={HeroMp4} type="video/mp4" />
                </video>

                <p className={s.subtitle}>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                </p>

            </div>

        </section>
    )
};

export default Hero;