import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select'

import * as s from './order-popup.module.scss';
import * as s_form from '@assets/styles/form.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const product_type_options = [
    { value: 'ZESTAW BASIC', label: 'ZESTAW BASIC' },
    { value: 'ZESTAW CUBE', label: 'ZESTAW CUBE' },
    { value: 'ZESTAW BUBU', label: 'ZESTAW BUBU' },
]

const customSelectStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontWeight: 300,
        paddingLeft: '8px',
        marginBottom: '-8px',
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: '#ED6839 !important',
        borderRadius: 0,
        borderWidth: '2px',
        boxShadow: state.isFocused ? '0px 4px 10px -5px rgba(66, 68, 90, .4); !important' : ''
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0px 8px'
    }),
    singleValue: (provided) => ({
        ...provided,
        fontWeight: 300,
        color: '#171717',
        marginBottom: '-8px',
        paddingLeft: '8px'
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'rgba(0, 0, 0, .05)' : '#fff !important',
        fontWeight: state.isSelected ? '700' : '300',
        color: '#3D3C3C',
        fontSize: '.875rem',
        paddintTop: '4px',
        paddingBottom: '4px'
    })
}

const OrderPopup = ({selectedProduct = '', managePopup}) => {

    const [reqStatus, setReqStatus] = useState({isSuccess: false, isLoading: false, isError: false, errorMessage: ''});
    const { register, handleSubmit, setValue, control, watch, formState: { errors, isValid } } = useForm({mode: 'onChange'});

    const submitHandler = async (data) => {
        console.log(data);
		try{
			setReqStatus({isSuccess: false, isLoading: true, isError: false, errorMessage: ''});
            const res = axios.post('https://mofu.store/API/send-contact-form.php', data);

			setReqStatus({isSuccess: true, isLoading: false, isError: false, errorMessage: ''});

		} catch (error) {
			setReqStatus({isSuccess: false, isLoading: false, isError: true, errorMessage: 'Wystąpił błąd.'});
		}
	}

    useEffect(() => {
        if(selectedProduct !== ''){
            setValue('product_type', product_type_options.find((c) => c.value === selectedProduct))
        }
    }, []);

    return (
        <>
            <div className={s.shadow} onClick={() => managePopup('')} />
            <div className={s.wrapper}>
                <div className={s.close} onClick={() => managePopup('')}></div>

                <h2>Zamówienie</h2>
                <p className={s.info}>
                    {/* cumsan vel facilisis sed do eiusmod tempor incididunt ut labore et dolore cumsan
                    vel facilisis sed do eiusmod tempor incididunt ut labore et dolore
                    cumsan vel facilisis sed do eiusmod tempor incididunt ut labore et dolore */}
                </p>

                <form onSubmit={handleSubmit(submitHandler)} className={`${s_form.apply} ${s.form}`}>

                    {/* name */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                            {errors.name?.type === 'required' && 'Wpisz imię i nazwisko.'}
                        </span>
                        <input id='form_contact_name' className={errors.name ? s_form.error : ''} placeholder="&nbsp;" type="text"
                            {...register("name",
                                {
                                    required: true
                                }
                            )}
                        />
                        <label htmlFor='form_contact_name'>Imię i nazwisko*</label>
                    </div>

                    {/* email */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                            {errors.email?.type === 'required' && 'Wpisz adres e-mail.'}
                            {errors.email?.type === 'pattern' && 'Adres email wygląda na nieprawidłowy.'}
                        </span>
                        <input id='form_contact_email' className={errors.email ? s_form.error : ''} placeholder="&nbsp;" type="email"
                            {...register("email",
                                {
                                    required: true,
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                }
                            )}
                        />
                        <label htmlFor='form_contact_email'>E-mail</label>
                    </div>

                    {/* tel */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                            {errors.tel?.type === 'required' && 'Wpisz numer telefonu.'}
                        </span>
                        <input id='form_contact_tel' className={errors.tel ? s_form.error : ''} placeholder="&nbsp;" type="tel"
                            {...register("tel",
                                {
                                    required: true
                                }
                            )}
                        />
                        <label htmlFor='form_contact_tel'>Numer telefonu</label>
                    </div>

                    {/* product */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                        </span>
                        <Controller
                            control={control}
                            name='product_type'
                            render={({ field: { onChange, value, name, ref } }) => (
                                <Select
                                    inputRef={ref}
                                    value={(selectedProduct !== '' && value === undefined) ? product_type_options.find((c) => c.value === selectedProduct) : product_type_options.find((c) => c.value === value)}
                                    name={name}
                                    options={product_type_options}
                                    styles={customSelectStyles}
                                    isClearable={true}
                                    isSearchable={false}
                                    inputId='form_contact_product_type'
                                    placeholder='Numer zestawu'
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption?.value);
                                    }}
                                />
                                )}
                        />
                        <label className={s_form.select_label} htmlFor='form_contact_product_type'>Numer zestawu</label>
                    </div>

                    {/* message */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                            {errors.message?.type === 'required' && 'Wpisz wiadomość.'}
                        </span>
                        <div className={s_form.textarea_wrapper}>
                            <textarea id='form_contact_message' className={errors.message ? s_form.error : ''} placeholder="&nbsp;"
                                {...register("message",
                                )}
                            />
                            <label htmlFor='form_contact_message'>Treść wiadomości</label>
                        </div>
                    </div>

                    {/* rules */}
                    <div className={s_form.field_wrapper}>
                        <span className={s_form.error_text}>
                            {errors.rules && 'Zaznacz wymagane zgody.'}
                        </span>
                        <div className={s_form.checkbox_wrapper}>
                            <input className={errors.rules ? s_form.error : ''} type="checkbox"
                                id={'form_contact_rules'}
                                {...register("rules",
                                    {
                                        required: true
                                    }
                                )}
                            />
                            <label className={s_form.checkbox_label} htmlFor={'form_contact_rules'}>Wysyłając formularz wyrażam zgodę na przetwarzanie podanych w formularzu danych. Zostałem poinformowany, że przysługuje mi prawo dostępu do danych, ich zmiany, usunięcia i zaprzestania przetwarzania.</label>
                        </div>
                    </div>

                    <div className={s_form.request_response}>
                        <span className={s_form.error}>
                            {reqStatus.isError && reqStatus.errorMessage}
                        </span>
                        <span className={s_form.success}>
                            {reqStatus.isSuccess && 'Wysłano!'}
                        </span>
                    </div>

                    {!reqStatus.isSuccess &&
                        <button type="submit" className={`${!isValid ? s_form.inactive : ''} ${s_btns.btn_full}`} >Wyślij</button>
                    }

                </form>

            </div>
        </>
    )
};

export default OrderPopup;
