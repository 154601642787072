import React, {useState} from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slick from 'react-slick';


import * as s from './offer-item.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import './slider.scss';

const OfferItem = ({data, managePopup}) => {


    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 6000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a className="dot">{i}</a>;
        }
    }

    const getColorClass = (color) => {
        switch (color) {
            case 'orange':
                return s.orange;
            case 'more_pink':
                return s.more_pink;
            case 'pink':
                return s.pink;
        }
    }

    const [bigImageData, setBigImageData] = useState(null);

    const renderPopup = () => {
        if(bigImageData !== null){
            return <>
                    <div className={s.shadow} onClick={() => setBigImageData(null)} />
                    <div className={s.wrapper}>
                        <div className={s.close} onClick={() => setBigImageData(null)}></div>
                        <GatsbyImage image={bigImageData} className={s.img} alt='Zdjęcie produktu' />
                    </div>
            </>;
        }
    }

    return (
        <>

            <div className={`${s.offer_item} offer_item ${s_wrapper.apply_height} ${data?.reversed && s.reversed} ${getColorClass(data.color)}`}>

                <div className={s.images}>

                    <Slick {...settings} className={s.slider_wrapper}>
                        {data.img.map((el, id) => (

                            <div key={id} className={s.slide_outer}>
                                <div className={s.slide}
                                     onClick={() => setBigImageData(data.img_full[id])}
                                >
                                    <GatsbyImage image={el} className={s.img} alt='Zdjęcie produktu'

                                    />
                                </div>
                            </div>

                        ))}
                    </Slick>

                </div>


                <div className={s.content}>
                    <h3>{data.title}</h3>

                    {/* <div className={s.price}>
                        <span className={s.old}>{data.old_price}</span>
                        <span className={s.curr}>{data.price}</span>
                    </div> */}

                    {/*<div className={s.dates}>
                        <span className={s.premiere}>Data premiery: {data.premiere_date}</span>
                        <span className={s.shipping}>Planowana wysyłka: {data.shipping_date}</span>
                    </div> */}

                    {/*<div className={s.contents}>*/}
                    {/*    <h4>Co w zestawie:</h4>*/}
                    {/*    <ul>*/}
                    {/*        {data.contents.map((el, id) => (*/}
                    {/*            <li key={id}>{el}</li>*/}
                    {/*        ))}*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className={s.contents}>
                        <p>
                            {data.about}
                        </p>
                    </div>

                    {/* <div className={s.sizes}>
                        <h4>Wymiary:</h4>
                        <ul>
                            {data.sizes.map((el, id) => (
                                <li key={id}>{el}</li>
                            ))}
                        </ul>
                    </div> */}

                    <button className={s_btns.btn_full} onClick={() => managePopup(data.title)}>
                        ZAMÓW<br/>
                        W PRZEDSPRZEDAŻY
                    </button>

                </div>


            </div>

            {renderPopup()}

        </>
    )
};

export default OfferItem;
