// extracted by mini-css-extract-plugin
export var apply = "form-module--apply--wR5SV";
export var apply_monocolor = "form-module--apply_monocolor--QCeF5";
export var arrow = "form-module--arrow--vEJ4e";
export var checkbox_label = "form-module--checkbox_label--MIfTn";
export var checkbox_wrapper = "form-module--checkbox_wrapper--j7alr";
export var error = "form-module--error--4YdP4";
export var error_text = "form-module--error_text--zn5gg";
export var field_wrapper = "form-module--field_wrapper--cwMft";
export var inactive = "form-module--inactive--03AsK";
export var request_response = "form-module--request_response--iOm6J";
export var select_label = "form-module--select_label--ROIfM";
export var success = "form-module--success--mP8um";
export var textarea_wrapper = "form-module--textarea_wrapper--5oTBR";